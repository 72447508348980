<script setup lang="ts">
import { useField } from 'vee-validate';
import { useAttrs, watch, computed, ref } from 'vue';

interface Props {
  id?: string
  type?: string
  modelValue?: string | number
  label: string
  placeholder?: string
  name: string
  required?: boolean
  attention?: boolean
  error?: boolean
  labelOutside?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  id: undefined,
  type: 'text',
  modelValue: undefined,
  placeholder: undefined,
  errorMessage: undefined,
  attention: false,
  error: false,
  labelOutside: false,
});

const emit = defineEmits<{(e: 'update:modelValue', value: number | string): void}>();

const showLabelInside = computed(() => !!inputValue.value);

const showPassword = ref(false);

function togglePassword() {
  showPassword.value = !showPassword.value;
}

const passwordType = computed(() => (showPassword.value ? 'text' : 'password'));

const {
  meta,
  value: inputValue,
  errorMessage,
  handleBlur,
  handleChange,
} = useField(props.name, undefined, {
  initialValue: props.modelValue,
});

watch(inputValue, (newVal) => {
  if (newVal === props.modelValue) {
    return;
  }
  emit('update:modelValue', newVal);
});
watch(
  () => props.modelValue,
  (newModel) => {
    if (newModel) {
      if (newModel === inputValue.value) {
        return;
      }
      inputValue.value = newModel;
    }
  },
);

const attrs = useAttrs();

</script>
<script lang="ts">
const options = { inheritAttrs: false };
export { options };
</script>

<template>
  <div
    class="flex w-full flex-col"
    :class="attrs['class']"
  >
    <base-label
      v-if="labelOutside"
      :label="label"
      :name="name"
      :required="required"
      class="mb-4 text-black"
    />
    <div class="relative flex">
      <slot name="before" />
      <div
        v-if="showLabelInside"
        class="absolute top-1.5 flex h-14 items-center px-3"
      >
        <slot name="prefix" />
      </div>
      <base-label
        v-if="!labelOutside && showLabelInside"
        :label="label"
        :name="name"
        :required="required"
        class="absolute ml-3 mt-1 text-xs text-z-gray-500"
      >
        <slot name="label" />
      </base-label>
      <component
        :is="type === 'textarea' ? 'textarea' : 'input'"
        v-bind="attrs"
        :id="id"
        :value="inputValue"
        :placeholder="placeholder"
        :name="name"
        :type="type === 'password' ? passwordType : type"
        :required="required"
        :aria-label="label"
        class="h-14 w-full rounded border border-z-gray-200 text-gray-900 outline-none
          read-only:bg-z-gray-100 read-only:text-z-gray-600 focus:border-z-gray-600 disabled:border-z-gray-600
          disabled:bg-z-gray-100 disabled:text-z-gray-600"
        :class="{
          'border-z-red': error || (!!errorMessage && meta.touched),
          'border-z-turquoise-600': attention,
          'p-3': labelOutside || !showLabelInside,
          'px-3 pb-0 pt-3': !labelOutside && showLabelInside,
          'placeholder:text-z-gray-900': !labelOutside,
          'placeholder:text-z-gray-600': labelOutside,
          'pr-11': type === 'password',
          'h-32': type === 'textarea',
          'pt-5': type === 'textarea' && showLabelInside,
          'appearance-none': type === 'number',
          'pl-6' : $slots.prefix && showLabelInside,
          'rounded-l-none': $slots.before,
        }"
        @input="handleChange"
        @blur="handleBlur"
      />
      <button
        v-if="type === 'password'"
        class="absolute right-0 flex h-14 items-center justify-center px-3"
        type="button"
        @click.prevent="togglePassword"
      >
        <img
          v-if="showPassword"
          src="@/assets/icons/eye-off.svg"
        >
        <img
          v-else
          src="@/assets/icons/eye.svg"
        >
      </button>
      <slot
        name="after"
      />
    </div>
  </div>
</template>
