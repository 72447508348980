<script setup lang="ts">
import { ref } from 'vue';
import googleRegisterButton from './google-register-button.vue';

interface Props {
  showForm: boolean,
}

const props = defineProps<Props>();
const showMailForm = ref(props.showForm);

function toggleMailForm() {
  showMailForm.value = !showMailForm.value;
}
</script>
<template>
  <slot v-if="showMailForm" />
  <div
    v-else
    class="space-y-4"
  >
    <google-register-button />
    <button
      class="flex h-12 w-full items-center justify-center space-x-2 rounded border border-z-gray-400 text-black"
      @click="toggleMailForm"
    >
      <img src="@/assets/icons/mail.svg">
      <p>Registro manual</p>
    </button>
  </div>
</template>
