<script setup lang="ts">
import { useStore } from '@/store/store';

const store = useStore();

function handleClick() {
  const refcode = store.referralCode;

  const queryParam = refcode ? `?refcode=${refcode}` : '';
  window.location.href = `/usuarios/registro/google_interstitial${queryParam}`;
}

</script>
<template>
  <button
    class="flex h-12 w-full items-center justify-center space-x-2 rounded border border-z-gray-400 text-black"
    type="button"
    @click="handleClick"
  >
    <img src="@/assets/icons/gmail.svg">
    <p>Continuar con Google</p>
  </button>
</template>
