<script setup lang="ts">
import { inject, ref } from 'vue';
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue';
import userUrl from '@/assets/icons/user.svg';
import defaultProfileUrl from '@/assets/icons/default-profile.svg';
import profileMenu from './profile-menu.vue';

interface Props {
  activeItem?: number;
}
const props = withDefaults(defineProps<Props>(), {
  activeItem: -1,
});

const user = inject<User>('user');

const activeIndex = ref(props.activeItem);

function goToHome() {
  if (window.location.pathname !== '/') {
    window.location.href = '/';
  }
}

function goToPartnerStart() {
  const pathnames = ['/productos/crear', '/como_subir'];
  if (!pathnames.includes(window.location.pathname)) {
    window.location.href = '/como_subir';
  }
}

function goToChats() {
  if (window.location.pathname !== '/chats') {
    window.location.href = '/chats';
  }
}

function goToNewProductRequest() {
  if (window.location.pathname !== '/necesidades_clientes') {
    window.location.href = '/necesidades_clientes/crear';
  }
}

function goToProductRequests() {
  if (window.location.pathname !== '/necesidades_clientes') {
    window.location.href = '/necesidades_clientes';
  }
}

const MENU_ITEMS = [
  {
    name: 'Inicio',
    action: goToHome,
  },
  {
    name: 'Sube tus productos',
    action: goToPartnerStart,
  },
  {
    name: '¿No encuentras lo que buscas?',
    action: goToNewProductRequest,
  },
  {
    name: 'Necesidades de nuestros clientes',
    action: goToProductRequests,
  },
  {
    name: 'Chats',
    action: goToChats,
  },
];

</script>
<template>
  <div class="hidden shrink-0 items-center space-x-6 text-xs text-z-gray-900 md:flex">
    <button
      v-for="(item, index) in MENU_ITEMS"
      :key="index"
      class="flex items-center"
      @click="activeIndex = index; item.action()"
    >
      <p class="transition hover:text-z-turquoise-700">
        {{ item.name }}
      </p>
    </button>
    <Popover
      v-if="user"
      v-slot="{ open }"
      class="relative"
    >
      <PopoverButton

        class="flex items-center space-x-2 rounded-full border py-1 pl-3 pr-1 focus:outline-none"
        :class="{
          'border-white': !open,
          'border-z-gray-200 bg-z-gray-50': open
        }"
      >
        <p class="transition hover:text-z-turquoise-700">
          Mi perfil
        </p>
        <img
          :src="user.picture.webpSm.url || defaultProfileUrl"
          class="aspect-square w-6 rounded-full border border-z-gray-200 object-cover p-0.5"
        >
      </PopoverButton>
      <PopoverPanel class="absolute right-0 top-10 w-96 rounded border border-z-gray-100 bg-white text-sm shadow-md">
        <profile-menu />
      </PopoverPanel>
    </Popover>
    <a
      v-else
      href="/usuarios/iniciar_sesion"
      class="flex items-center space-x-2 rounded-full border border-z-gray-200 bg-z-gray-25 py-1 pl-3 pr-1"
    >
      <p>Iniciar sesión</p>
      <img
        :src="userUrl"
        class="w-6 rounded-full border border-z-gray-200 bg-white"
        alt="Ícono de usuario genérico"
      >
    </a>
  </div>
</template>
