
<script setup lang="ts">
import { computed } from 'vue';

interface Props {
  total: number,
  value: number,
}
const props = defineProps<Props>();

const width = computed(() => {
  // eslint-disable-next-line no-magic-numbers
  const value = props.value * 100 / props.total;

  return `${value}%`;
});
</script>
<template>
  <!-- eslint-disable tailwindcss/no-custom-classname -->
  <div class="h-2 w-full rounded-full bg-z-gray-200">
    <div
      class="progress h-2 rounded-full bg-z-turquoise-600"
    />
  </div>
</template>
<style lang="css" scoped>
.progress {
  width: v-bind('width');
}
</style>
