<script setup lang="ts">
import { referralCodesApi } from '@/api/referral-codes';
import { useStore } from '@/store/store';
import { debounce } from 'lodash';
import { onMounted, ref, watch } from 'vue';

const referralCode = ref(new URLSearchParams(window.location.search).get('refcode') ?? '');

const foundCode = ref<ReferralCode|null>();

const loading = ref(false);
const error = ref(false);
const showReferralInfo = ref(false);

const store = useStore();

function findReferralCode() {
  showReferralInfo.value = true;
  loading.value = true;
  error.value = false;
  referralCodesApi.search(referralCode.value)
    .then((response) => {
      if (response.data?.referralCode) {
        foundCode.value = response.data.referralCode;
        store.setReferralCode(referralCode.value);
      } else {
        error.value = true;
        foundCode.value = null;
        store.setReferralCode('');
      }
      loading.value = false;
    });
}

watch(referralCode, () => {
  loading.value = true;
  // eslint-disable-next-line no-magic-numbers
  debounce(findReferralCode, 500)();
});

onMounted(() => {
  if (referralCode.value) {
    findReferralCode();
  }
});
</script>
<template>
  <base-input
    id="refcode"
    v-model="referralCode"
    name="refcode"
    label="Código de referido (opcional)"
    placeholder="Código de referido (opcional)"
    :error="error"
  />
  <div
    v-if="showReferralInfo && referralCode"
    class="flex w-full flex-col items-center rounded-lg bg-z-gray-50 p-4 text-center text-sm"
  >
    <img
      v-if="loading"
      src="@/assets/icons/loading.svg"
      class="w-10 animate-spin-slow"
    >
    <template v-else-if="error">
      El código ingresado no es válido
    </template>
    <template
      v-else-if="foundCode && foundCode?.redeemsCount >= foundCode?.maxRedeems"
    >
      El código ya ha sido canjeado.
    </template>
    <template
      v-else-if="foundCode"
    >
      <img
        v-if="foundCode.user?.picture.webpXs?.url"
        :src="foundCode.user?.picture.webpXs?.url"
        class="h-10 w-10"
      >
      <img
        v-else
        src="@/assets/icons/default-profile.svg"
        class="h-10 w-10"
      >
      <p class="mt-2 text-base">
        ¡{{ foundCode.user?.firstName }} te ha invitado!
      </p>
      <p class="text-xs text-z-gray-800">
        Ganarás $3.000 al subir tus 3 primeros productos.
      </p>
    </template>
  </div>
</template>
