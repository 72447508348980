<script setup lang="ts">
import { onMounted, ref } from 'vue';

interface Props {
  authenticityToken: string,
}
defineProps<Props>();

const googleForm = ref<HTMLFormElement>();

onMounted(() => {
  if (googleForm.value) {
    googleForm.value.requestSubmit();
  }
});
</script>
<template>
  <form
    ref="googleForm"
    action="/users/auth/google_oauth2"
    method="post"
  >
    <input
      name="authenticity_token"
      type="hidden"
      :value="authenticityToken"
    >
  </form>
</template>
