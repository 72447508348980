<script setup lang="ts">
import { useStore } from '@/store/store';
import { storeToRefs } from 'pinia';

const store = useStore();

const { referralCode } = storeToRefs(store);
</script>
<template>
  <input
    id="refcode"
    v-model="referralCode"
    hidden
    name="refcode"
  >
</template>
