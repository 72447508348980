import { api } from './index';

export const partnerAccountsApi = {
  create(userId:number) {
    const path = '/api/internal/partner_accounts';

    return api({
      method: 'post',
      url: path,
      data: { userId },
    });
  },

  products(id:number) {
    const path = `/api/internal/partner_accounts/${id}/products`;

    return api({
      method: 'get',
      url: path,
    }).then(response => response.data.products as Product[]);
  },

  earnings(id:number) {
    const path = `/api/internal/partner_accounts/${id}/earnings`;

    return api({
      method: 'get',
      url: path,
    }).then(response => response.data as PartnerEarnings);
  },
};
