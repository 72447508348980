import { api } from './index';

export const referralCodesApi = {
  search(code: string) {
    const path = `/api/internal/referral_codes/search?code=${code}`;

    return api({
      method: 'get',
      url: path,
    });
  },
};
